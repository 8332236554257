// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---node-modules-gatsby-plugin-offline-app-shell-js": () => import("/opt/build/repo/node_modules/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---node-modules-gatsby-plugin-offline-app-shell-js" */),
  "component---src-templates-category-template-js": () => import("/opt/build/repo/src/templates/CategoryTemplate.js" /* webpackChunkName: "component---src-templates-category-template-js" */),
  "component---src-templates-post-template-js": () => import("/opt/build/repo/src/templates/PostTemplate.js" /* webpackChunkName: "component---src-templates-post-template-js" */),
  "component---src-templates-page-template-js": () => import("/opt/build/repo/src/templates/PageTemplate.js" /* webpackChunkName: "component---src-templates-page-template-js" */),
  "component---src-pages-404-js": () => import("/opt/build/repo/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-category-js": () => import("/opt/build/repo/src/pages/category.js" /* webpackChunkName: "component---src-pages-category-js" */),
  "component---src-pages-co-oferujemy-js": () => import("/opt/build/repo/src/pages/co-oferujemy.js" /* webpackChunkName: "component---src-pages-co-oferujemy-js" */),
  "component---src-pages-dlaczego-my-js": () => import("/opt/build/repo/src/pages/dlaczego-my.js" /* webpackChunkName: "component---src-pages-dlaczego-my-js" */),
  "component---src-pages-index-js": () => import("/opt/build/repo/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-kontakt-js": () => import("/opt/build/repo/src/pages/kontakt.js" /* webpackChunkName: "component---src-pages-kontakt-js" */),
  "component---src-pages-o-nas-js": () => import("/opt/build/repo/src/pages/o-nas.js" /* webpackChunkName: "component---src-pages-o-nas-js" */)
}

exports.data = () => import("/opt/build/repo/.cache/data.json")

